html {
  font-size: 14px; /* Start value for rem/em */
}

.App {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.app-header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .menu {
    background: #28598a !important;
    .logo-menu {
      width: 2.1em!important;
      margin-right: 0.2em !important;
    }
  }
}

.app-content {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 100px;
  right: 0;
  overflow: auto;
}

.home .photo-full{
  max-width: 20rem;
  float:left;
  margin-right:1rem
}

.about{
  .title{
    text-align: left;
    margin-left: 1.5rem;
  }
 .photo-profile{
    max-width: 24.5rem;
    float:right;
    margin: 0.8rem 0 0 1rem;
  }
}

.App-link {
  color: #61dafb;
}

.home,
.about,
.psychotherapy,
.prices,
.contact {
  margin-top: 5em;
  margin-bottom: 2em;
}

.home p{
  text-align: justify;
}

.about, .psychotherapy, .prices{
  ul{
    text-align: justify;
  }
}

.prices{
  h1{
    margin-bottom: 2em;
  }
}

.app-footer.ui.segment {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #28598a !important;
  padding: 0.5rem;
  border-radius: 0;
  .logo-full {
    margin-bottom: 0;
  }
  a {
    color: #ffffff;
    font-weight: 600;
  }
  a:hover {
    color: #aedfff;
  }
} 